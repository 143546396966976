// Grayscale
$white:                 #fff !default;
$gray-100:              #f7f7fc !default;
$gray-200:              #f3f3f9 !default;
$gray-300:              #e9e9f2 !default;
$gray-400:              #CACACA !default;
$gray-500:              #9e9fb4 !default;
$gray-600:              #737491 !default;
$gray-700:            	#666 !default;
$gray-800:              #4a4b65 !default;
$gray-900:              #37384e !default;
$black:                 #000 !default;

// Brand colors
$primary:               #44D62C !default;
$secondary:             #005B57 !default;
$info:		            #2AEEFB !default;
$success:               #009479 !default;
$warning:               #FAE300 !default;
$danger:                #FF0000 !default;
$light:                 $gray-400 !default;
$dark:                  $gray-700 !default;

$green:			#003434 !default;
$orange:		#E77200 !default;
$yellow:		#FAE300 !default;
$blue:			#1B1464 !default;
$cyan:			#4B00FF !default;

$font-family-sans-serif:      'ddin-exp', sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

$enable-rounded: true !default;
$enable-negative-margins:     true !default;
$enable-cssgrid:              true !default;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4.5,
  7: $spacer * 6,
  8: $spacer * 7.5,
  9: $spacer * 9,
  10: $spacer * 12,
) !default;

@import "node_modules/bootstrap/scss/bootstrap";